.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Credit to: https://stackoverflow.com/questions/48885355/react-facebook-login-button-style */
.btnFacebook {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0 transparent;
  text-align: center;
  margin: 5px;
  display: inline-block;
}

.btnGoogle {
  margin: 5px;
  width: 100%;
  height: 42px;
  border-radius: 4px;
  background: #db3236;
  color: white;
  border: 0 transparent;
  text-align: center;
}

.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}

.btnGoogle:hover {
  background: #db3236;
  opacity: 0.6;
}

@media only screen and (max-width: 399px) {
  .btnFacebook,
  .btnGoogle {
    width: 100%;
  }
}

body {
  overflow: hidden; /* Hide scrollbars */
}
